<template>
  <GpPageTemplate my-info-page @back="back" :title="title" v-if="template && load" root-route="MyInfo" :structure="structure" app="myinfo">
    <template #header="{isBackButton}">
      <SubHeader :title="$t(structure.title)" @back="back" :is-back-button="isBackButton" />
    </template>
    <template #content="{success, error, loading}">
      <component v-if="pageLoad" :is="template" :structure="structure" @changeStep="changeStep" :model="model" :step="step" @success="success" @error="error" @loading="loading" :is-title="true" :is-desc="true" />
    </template>
  </GpPageTemplate>
</template>

<script>
import GpPageTemplate from '@/views/components/gg-pass/GpPageTemplate.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import SubHeader from '@/views/components/gg-pass/SubHeader.vue';
import Specific from '@shared/types/Specific';
import { routeNames } from '@/constants/model/my-page';
import { STEP } from '@/constants/base/my-page';

export default {
  name: 'MyInfoProgress',
  components: { SubHeader, GpDescription, GpTitle, GpPageTemplate },
  props: {
    structure: Specific,
    load: { type: Boolean, default: false },
  },
  data() {
    return {
      step: null,
      isDataLoaded: false,
      template: null,
      title: null,
      uid: 0,
      model: Specific,
      pageLoad: false,
    };
  },
  computed: {
    homeRoutes() {
      return [routeNames.NpMobileNumber, routeNames.NpEmailAddress, routeNames.NpFundPassword, routeNames.NpChangeLoginPassword, routeNames.NpLoginSecurity, routeNames.NpMyAccounts, routeNames.NpMyDocuments, routeNames.NpPersonalInfo, routeNames.NpSocialAccounts];
    }
  },
  watch: {
    $route: 'update',
  },
  methods: {
    back() {
      if (this.$myinfo.isFirstStep) {
        if (this.homeRoutes.includes(this.$route.name)) {
          this.replaceRouteName('MyInfo');
          return;
        }
        this.$router.back();
      } else {
        if (this.$myinfo.isFirstStep && this.$myinfo.currentStepIndex <= 0) return;
        this.changeStep(this.$myinfo.stepList[this.$myinfo.currentStepIndex - 1]);
      }
    },
    changeStep(step) {
      this.$myinfo.changeStep(step);
      this.step = step;
    },
    async update() {
      this.pageLoad = false;
      this.$store.commit('env/setTheme', 'white');
      this.$myinfo.changeStep(STEP.DEFAULT);
      this.$myinfo.updateRouteName(this.$route.name);
      this.$myinfo.createAction();
      this.step = this.$myinfo.step;
      this.template = this.$myinfo.currentComponent;
      this.structureReset();
      if (this.$route.name !== routeNames.NpVerifyCode) this.structure.title = this.$myinfo.title;
      this.pageLoad = true;
    },

    async myInfoFetch() {
      const r = await this.$myinfo.fetch({payload : null, block : true});
      if (r?.error) {
        const replace = this.$myinfo.createErrorProxy(r);
        if (replace) {
          this.replaceRouteName(replace.path, replace.params, replace.query);
          return null;
        }
      }
      return r;
    },

    structureReset() {
      this.structure.showHistory = false;
      this.structure.backRoute = null;
      this.structure.buttonName = 'ok';
      this.structure.current = null;
    },
  },
  mounted() {
    this.model = { ...this.$myinfo.model };
    this.update();
  },
  beforeDestroy() {
    this.model = { ...this.$myinfo.model };
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-info-page] { .flex(); .flex-dc(); flex: 1;
  .desc { .c(black);}
  .submit-button-group { .gap(10); .flex(); .flex-dc();
    @media (@ml-up) {
      .flex-dr();
    }
  }
}
</style>